<template>
  <main id="main" class="main">
    <router-view />
  </main>
</template>

<script lang="ts">
  export default {
    name: 'app',
  }
</script>
